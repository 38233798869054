<template>
  <div class="hello">
    <div class="al login">
			<img class="imgclass" src=".././assets/icons/404i.png">
		</div>
		<br>
  </div>
</template>

<script>
export default {
  name: 'hello',
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.al.container-fluid{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-size: 12px;
    height: 100%;
}
.al.title{
    padding-top: 2%;
font-size: medium;
font-size: 28px;
font-family: lato;
letter-spacing: 0;
color: #474747;
text-transform: capitalize;
opacity: 1;
}
.al.login{
    text-align: center;
}
.al.body{
font-size: medium;
font-size: 20px;
font-family: lato;
letter-spacing: 0;
color: #707070;
opacity: 1; 
padding-bottom: 5%;
}
.imgclass{
  max-width: 90vw;
  max-height: 80vh;
}
</style>
